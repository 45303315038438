html {
  box-sizing: border-box;
  width: 100%;
}

body {
  width: 100vw;
  min-height: 100vh;
  background-color: #F4F4F4 !important;
  margin: 0;
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.text-overflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.c-p {
  cursor: pointer;
  
}

.flying {
  box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.05);
  transition: box-shadow .2s ease-in-out;
}

.flying:hover {
  box-shadow: 0 0 8px 1px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 0 8px 1px rgba(0, 0, 0, 0.1);
}

.recipe-card-image {
  min-height: 100%;
  max-height: 100%;
  width: 33% !important;
  object-fit: cover;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
